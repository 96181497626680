import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import Image from 'next/image'
import type {BlockWithClassName} from '@/_new-code/services/kontent-ai/types'
import {pushToDataLayer} from '@/utils/analytics'
import {loadImageFromKontentAI} from '@/imageLoader'

export type ResponsiveLinkWithIconContentItem = IContentItem<{
	text: Elements.TextElement
	url: Elements.TextElement
	icon: Elements.AssetsElement
	openInNewTab: Elements.MultipleChoiceElement
}>
interface ResponsiveLinkWithIconProps {
	icon?: string
	isExternalLink?: boolean
	text?: string
	url?: string
}
const ResponsiveLinkWithIcon = ({
	icon = '',
	isExternalLink = false,
	text = '',
	url = '',
}: ResponsiveLinkWithIconProps): JSX.Element => {
	const handleClick = (): void => {
		pushToDataLayer({
			event: 'cta_click',
			cta_name: text,
			cta_category: 'CTA_Button',
		})
	}
	return (
		<a
			href={url}
			onClick={handleClick}
			rel="noreferrer"
			target={isExternalLink ? '_blank' : '_self'}
		>
			<div className="flex cursor-pointer flex-col items-center justify-center md:flex-row">
				<Image
					alt=""
					className="mb-1 h-8 w-8 md:m-0"
					height={32}
					loader={loadImageFromKontentAI}
					src={icon}
					width={32}
				/>
				<span className="mt-3 flex h-10 items-center justify-center text-center leading-tight hover:underline md:mt-0 md:h-auto md:pl-2 md:text-left">
					{text}
				</span>
			</div>
		</a>
	)
}

export const ResponsiveLinkWithIconBlock: BlockWithClassName<
	ResponsiveLinkWithIconContentItem
> = ({className, block}) => {
	const {locale = ''} = useRouter()
	const isExternalLink = block.elements.openInNewTab[0]?.codename === 'yes'
	const optionalLocalePath = block.elements.url.startsWith('/')
		? `/${locale}`
		: ''

	return (
		<div
			className={className || ''}
			data-kontent-element-codename={block.system.codename}
		>
			<ResponsiveLinkWithIcon
				icon={block.elements.icon[0]?.url}
				isExternalLink={isExternalLink}
				text={block.elements.text}
				url={`${optionalLocalePath}${block.elements.url}`}
			/>
		</div>
	)
}
